<template>
  <div>
    <!-- Show the switch tabs -->
    <v-tabs
      v-if="isContentDiscoveryAllowed"
      v-model="selectedTabIndex"
      background-color="transparent"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.key"
      >
        <v-icon left>
          {{ tab.icon }}
        </v-icon>

        {{ tab.text }}
      </v-tab>
    </v-tabs>

    <!-- Show the tab content here -->
    <v-data-table
      class="mt-6"
      :key="selectedTabIndex"
      :headers="tableHeaders[selectedTab]"
      :items="response.data"
      :server-items-length="response.total"
      :page="queryPage"
      :items-per-page="queryPerPage"
      @update:page="queryPage = $event"
      @update:items-per-page="queryPerPage = $event"
    >
      <!-- Modify the platform column -->
      <template v-slot:item.platform="{ item }">
        <div>
          <v-img
            :src="`/img/socials/${item.platform}.svg`"
            max-width="32"
            height="32"
          ></v-img>
        </div>
      </template>

      <!-- Modify the influencer/preview column -->
      <template v-slot:item.username="{ item }">
        <profile-chip
          :data="item.preview"
          :platform="item.platform"
        />
      </template>

      <!-- Modify the title column -->
      <template v-slot:item.title="{ item }">
        <div>
          <div class="text-subtitle-2">
            {{ item.title || "-" }}
          </div>

          <div class="text-caption white-space-nowrap" :title="formatDate(item.created_at)">
            {{ formatRelativeDate(item.created_at) }}
          </div>

          <div
            v-if="item.user.id !== profileData.id"
            class="pb-2 pt-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon small left>
                    person
                  </v-icon>

                  <span >
                    {{ item.user.name }}
                  </span>
                </v-chip>
              </template>

              <span>
                {{ item.user.email }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </template>

      <!-- Modify the filters column -->
      <template v-slot:item.filters="{ item }">
        <div @click="handleViewFilters(item)">
          <preview-filters :filters="item.filters" :limit="2" />
        </div>
      </template>

      <!-- Modify the influencers count column -->
      <template v-slot:item.count="{ item }">
        <div :title="item.count">
          <div class="text-subtitle-2">
            {{ nFormatter(item.count) }}
          </div>
        </div>
      </template>

      <!-- Modify the posts count column -->
      <template v-slot:item.posts="{ item }">
        <div
          v-if="item.status === 'completed'"
          :title="item.posts"
        >
          <div class="text-subtitle-2">
            {{ nFormatter(item.posts_count) }}
          </div>
        </div>

        <status-chip
          v-else
          :status="item.status"
        />
      </template>

      <!-- Modify the action column -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <!-- Show the view button -->
          <v-btn
            small
            depressed
            color="primary"
            @click="handleView(item)"
            :disabled="shouldViewBeDisabled(item)"
          >
            View

            <v-icon right>
              open_in_new
            </v-icon>
          </v-btn>

          <!-- Show additional options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the edit button -->
              <v-list-item
                v-if="item.user.id === profileData.id"
                @click="handleEdit(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                v-if="item.user.id === profileData.id"
                @click="handleDelete(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the share button -->
              <v-list-item
                @click="copySavedSearchLink(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Copy URL
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the view filters button -->
              <v-list-item
                v-if="item.filters || item.misc.filters"
                @click="handleViewFilters(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    View Filters
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- The dialog for viewing all the filters -->
    <v-dialog
      v-model="shouldShowFiltersDialog"
      max-width="600"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text pt-2">
          Influencer Discovery Search Filters
        </v-card-title>

        <v-card-text class="pt-3">
          <preview-filters :filters="selectedItemFilters" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            @click="handleRunFilters"
          >
            Run these filters

            <v-icon right>
              saved_search
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the dialog for deleting -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Delete this record?
        </v-card-title>

        <v-card-text class="pt-4">
          You cannot undo this action. It'll be deleted forever.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the dialog for editing -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this record
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copySavedSearchLink"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model="form.title"
            label="Search Name"
            placeholder="Optional"
            @input="$v.form.title.$touch()"
            @blur="$v.form.title.$touch()"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="mb-3"
            outlined
            dense
          ></v-text-field>

          <v-textarea
            v-model="form.notes"
            label="Optional notes"
            @input="$v.form.notes.$touch()"
            @blur="$v.form.notes.$touch()"
            :hide-details="!$v.form.notes.$anyError"
            :error-messages="$v.form.notes.$anyError ? ['Please enter a note under 500 characters'] : null"
            :counter="500"
            outlined
            dense
          ></v-textarea>

          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper validator functions
import { required, maxLength } from "vuelidate/lib/validators"

// Import helper functions
import filterConverter from "@/helpers/filterConverter"

// Import children components
const UserAvatar = () => import(/* webpackChunkName: "user-avatar" */ "@/blocks/common/UserAvatar")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const PreviewFilters = () => import(/* webpackChunkName: "preview-filters" */ "@/components/common/PreviewFilters.vue")

// Export the SFC
export default {
  // Name of the component
  name: "SavedSearches",

  // Register the child components
  components: {
    UserAvatar,
    StatusChip,
    ProfileChip,
    PreviewFilters
  },

  // Accept incoming data from parent
  props: {
    defaultTab: {
      type: String,
      default: "influencerDiscovery"
    }
  },

  // Define local data variables
  data: () => ({
    selectedTabIndex: 0,
    tabs: [
      {
        icon: "person_search",
        key: "influencerDiscovery",
        text: "Influencer Discovery",
      },
      {
        icon: "manage_search",
        key: "contentDiscovery",
        text: "Content Discovery"
      }
    ],

    tableHeaders: {
      'influencerDiscovery': [
        {
          text: "",
          value: "platform",
          sortable: false,
        },

        {
          text: "Title",
          value: "title",
          sortable: false
        },

        {
          text: "Filters",
          value: "filters",
          sortable: false
        },

        {
          text: "Influencers",
          value: "count",
          sortable: false,
        },

        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "end"
        }
      ],

      'contentDiscovery': [
        {
          text: "",
          value: "platform",
          sortable: false,
        },

        {
          text: "Influencer",
          value: "username",
          sortable: false,
        },

        {
          text: "Title",
          value: "title",
          sortable: false
        },

        {
          text: "Posts",
          value: "posts",
          sortable: false,
        },

        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "end"
        }
      ]
    },

    selectedItem: null,
    shouldShowEditDialog: false,
    shouldShowDeleteDialog: false,
    shouldShowFiltersDialog: false,

    form: {
      title: "",
      notes: "",
      sharingAccess: "self"
    },
  }),

  // Define computable properties
  computed: {
    /**
     * Get the profile data from the store
     *
     * @returns {Object} The profile data
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Whether or not the currently authenticated user is whitelisted or not
     *
     * @returns {Boolean}
     */
    isWhitelisted() {
      return this.$store.getters["auth/isWhitelisted"]
    },

    /**
     * Is the authenticated user allowed to discover content
     *
     * @return {Boolean}
     */
    isContentDiscoveryAllowed() {
      return  this.$store.getters["auth/isServiceAllowed"](this.constants.model.user.allowedServices.actionContentDiscovery)
    },

    /**
     * Get the selected tab key
     *
     * @returns {String}
     */
    selectedTab() {
      return this.tabs[this.selectedTabIndex].key
    },

    /**
     * Get the selected item sort
     *
     * @returns {Object}
     */
    selectedItemSort() {
      return this.selectedItem?.sort
    },

    /**
     * Get the selected item filters
     *
     * @returns {Object}
     */
    selectedItemFilters() {
      return this.selectedItem?.filters || this.selectedItem?.misc?.filters || {}
    },

    /**
     * Get the selected item audience source
     *
     * @returns {String}
     */
    selectedItemAudienceSource() {
      return this.selectedItem?.audience_source
    },

    /**
     * Get API response from the Vuex store
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters[`savedSearches/${this.selectedTab}/response`]
    },

    /**
     * Get the queries from Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters[`savedSearches/${this.selectedTab}/query`]
    },

    /**
     * Get the current page number in pagination
     */
    queryPage: {
      get() {
        return this.query.page
      },

      set(value) {
        this.$store.dispatch(`savedSearches/${this.selectedTab}/updateQuery`, {
          key: "page",
          value
        })

        // Dispatch action to fetch data
        this.$store.dispatch(`savedSearches/${this.selectedTab}/fetchItems`)
      }
    },

    /**
     * Get the number of items per page
     */
    queryPerPage: {
      get() {
        return this.query.perPage
      },

      set(value) {
        this.$store.dispatch(`savedSearches/${this.selectedTab}/updateQuery`, {
          key: "perPage",
          value
        })

        // Dispatch action to fetch data
        this.$store.dispatch(`savedSearches/${this.selectedTab}/fetchItems`)
      }
    }
  },

  // Define vuelidate validations
  validations: {
    form: {
      title: {
        required,
        maxLength: maxLength(100)
      },
      notes: {
        maxLength: maxLength(500)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Whether or not the view button be disabled
     *
     * @param {Object} item
     * @returns {boolean}
     */
    shouldViewBeDisabled(item) {
      // If the same saved search is being viewed
      if (this.$route.name === 'SavedInfluencerDiscoverySearch' && this.$route.params.uuid === item.uuid) {
        return true
      }

      // If content discovery is being viewed
      if (this.selectedTab === "contentDiscovery") {
        // Check if the status is completed
        return item.status !== "completed"
      }

      // Fallback
      return false
    },

    /**
     * Handle the view button click
     *
     * @param {Object} item
     * @returns {void}
     */
    handleView(item) {
      // If influencerDiscovery is selected
      if (this.selectedTab === "influencerDiscovery") {
        this.$router.push({
          name: "SavedInfluencerDiscoverySearch",
          params: {
            uuid: item.uuid
          }
        })
      }

      // If contentDiscovery is selected
      else {
        this.$router.push({
          name: "ContentDiscoveryReport",
          params: {
            uuid: item.uuid
          }
        })
      }
    },

    /**
     * Show the edit form
     *
     * @param {Object} item
     * @returns {void}
     */
    handleEdit(item) {
      this.form.title = item.title
      this.form.notes = item.notes
      this.form.sharingAccess = item.sharing_access

      this.$v.form.$reset()

      this.selectedItem = item
      this.shouldShowEditDialog = true
    },

    /**
     * Handle the edit submit
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // Close the dialog
      this.shouldShowEditDialog = false

      // Update the item
      await this.$store.dispatch(`savedSearches/${this.selectedTab}/updateItem`, { ...this.selectedItem, ...this.form })
    },

    /**
     * Show the delete form
     *
     * @param {Object} item
     * @returns {void}
     */
    handleDelete(item) {
      this.selectedItem = item
      this.shouldShowDeleteDialog = true
    },

    /**
     * Handle the delete submit
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // Close the dialog
      this.shouldShowDeleteDialog = false

      // Delete the item
      await this.$store.dispatch(`savedSearches/${this.selectedTab}/deleteItem`, this.selectedItem)
    },

    /**
     * Show the view filters form
     *
     * @param {Object} item
     * @returns {void}
     */
    handleViewFilters(item) {
      this.selectedItem = item
      this.shouldShowFiltersDialog = true
    },

    /**
     * Run the filters
     *
     * @returns {void}
     */
    async handleRunFilters() {
      // Clear current discovery filters
      this.$store.dispatch("influencerDiscovery/resetFilters", false)

      // Apply the sort
      this.$store.dispatch("influencerDiscovery/updateSort", this.selectedItemSort)
      // Apply audience source
      this.$store.dispatch("influencerDiscovery/updateAudienceSource", this.selectedItemAudienceSource)

      // Apply the filters
      for (const key of Object.keys(this.selectedItemFilters)) {
        // If the value is an array
        if (Array.isArray(this.selectedItemFilters[key])) {
          // Loop through each value
          for (const item of this.selectedItemFilters[key]) {
            this.$store.dispatch("influencerDiscovery/addFilter", await filterConverter(key, item))
          }
        }
        // Otherwise
        else {
          this.$store.dispatch("influencerDiscovery/addFilter", await filterConverter(key, this.selectedItemFilters[key]))
        }
      }

      // Close the dialog
      this.shouldShowFiltersDialog = false
      this.$emit("close")

      // If the current route is not influencerDiscovery
      if (this.$route.name !== "InfluencerDiscovery") {
        this.$router.push({
          name: "InfluencerDiscovery"
        })
      }
      // Otherwise
      else {
        // Trigger the search
        window.dispatchEvent(new CustomEvent('triggerSearch', {
          detail: {
            module: "influencerDiscovery",
            origin: "SavedSearches/handleRunFilters"
          }
        }))
      }
    },

    /**
     * Copy the saved search link
     *
     * @return {void}
     */
    copySavedSearchLink(item) {
      // Copy the link value
      const route = this.$router.resolve({ name: "SavedInfluencerDiscoverySearch", params: { uuid: item.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },
  },

  /**
   * As soon as the component is created
   *
   * @returns {void}
   */
  created() {
    // If we are allowed to use content discovery
    if (this.isContentDiscoveryAllowed) {
      // Set the default tab
      this.selectedTabIndex = this.tabs.findIndex(tab => tab.key === this.defaultTab)
    }

    // Dispatch actions to fetch data
    this.$store.dispatch("savedSearches/influencerDiscovery/fetchItems")
    this.$store.dispatch("savedSearches/contentDiscovery/fetchItems")
  }
}
</script>
